import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/tactical-asset-allocation/"
          }}>{`Tactical Asset Allocation`}</a></li>
      </ul>
    </Aside>
    <p>{`The short answer is no, seriously. If that's what you are looking for, we are afraid our services are not for you. At TuringTrader.com, we aim to provide value to investors seeking to preserve what they have and grow their nest egg slowly but steadily without undue risk. We want to stay away from baseless claims and promises, and we want no part in 'financial pornography.' Instead, our approach to investing is data-driven, using thorough computer simulations to understand our portfolio's characteristics better.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8569722bd37eac14085156aa04a4c73b/47d47/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHmOJypZo//xAAbEAACAgMBAAAAAAAAAAAAAAABAgADBBESIv/aAAgBAQABBQJE6BqXe65jn09nZn//xAAWEQADAAAAAAAAAAAAAAAAAAAQEUH/2gAIAQMBAT8BVH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBQEBAAAAAAAAAAAAAAAAAREhMUEQgf/aAAgBAQAGPwLbwVHloKc87//EAB0QAAICAQUAAAAAAAAAAAAAAAERAFEhMUFhofD/2gAIAQEAAT8hQEOcCKRjpka4AanzhQa3L3UM9IU4TP/aAAwDAQACAAMAAAAQNw//xAAYEQACAwAAAAAAAAAAAAAAAAAAEQExUf/aAAgBAwEBPxCjDjD/xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhQf/aAAgBAgEBPxCcRZ//xAAdEAEBAAMAAgMAAAAAAAAAAAABEQAhMVFhgZGh/9oACAEBAAE/ELUBQbrno7gFpm9RrYvn8y6VW7Sa5hc5AvxcW3WhR1btyTz6z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "grow your nest egg slowly but steadily",
            "title": "grow your nest egg slowly but steadily",
            "src": "/static/8569722bd37eac14085156aa04a4c73b/e5166/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg",
            "srcSet": ["/static/8569722bd37eac14085156aa04a4c73b/f93b5/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg 300w", "/static/8569722bd37eac14085156aa04a4c73b/b4294/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg 600w", "/static/8569722bd37eac14085156aa04a4c73b/e5166/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg 1200w", "/static/8569722bd37eac14085156aa04a4c73b/d9c39/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg 1800w", "/static/8569722bd37eac14085156aa04a4c73b/df51d/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg 2400w", "/static/8569722bd37eac14085156aa04a4c73b/47d47/hello-i-m-nik-NYpOl-PJDkM-unsplash.jpg 3872w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Is this Gambling?`}</h2>
    <p>{`Again, the answer is no. We are investors like you, with a background in software engineering and signal-processing. We firmly believe that we can create an edge through quantitative analysis of historical prices. Our strategies attempt to benefit from known anomalies in the efficient markets, most importantly, momentum and mean-reversion. We combine this raw alpha with various money-management techniques. The resulting strategies are still volatile but have shown an edge in historical backtests.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ceec410d541a45951176b21c61e47878/b17f8/wall-street-ny.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBQb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFDmW5kCQf/xAAcEAACAgIDAAAAAAAAAAAAAAACBAEDABMSFTH/2gAIAQEAAQUCBiaZ7EsvPZar7rCcZHgx/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAgICAwAAAAAAAAAAAAAAAAECESGRMTJR/9oACAEBAAY/AuE8HRbHKqsV5MwjonFen//EAB0QAAICAQUAAAAAAAAAAAAAAAERACExEEFhcYH/2gAIAQEAAT8hBgOUK30E32JXkAUg1WIc4dhB4gI//9oADAMBAAIAAwAAABCgD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAQQDAAAAAAAAAAAAAAERACExQWGBweH/2gAIAQEAAT8QC2N9z4xrA+6QxU2wvjQY+Olgma4mq5tV6xLFsLwIM8WZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "data-driven investment portfolios",
            "title": "data-driven investment portfolios",
            "src": "/static/ceec410d541a45951176b21c61e47878/e5166/wall-street-ny.jpg",
            "srcSet": ["/static/ceec410d541a45951176b21c61e47878/f93b5/wall-street-ny.jpg 300w", "/static/ceec410d541a45951176b21c61e47878/b4294/wall-street-ny.jpg 600w", "/static/ceec410d541a45951176b21c61e47878/e5166/wall-street-ny.jpg 1200w", "/static/ceec410d541a45951176b21c61e47878/b17f8/wall-street-ny.jpg 1600w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Do you Beat the Market?`}</h2>
    <p>{`It depends on your angle. If you are serious about investing, you will probably agree that return figures are meaningless unless considered within proper context. There are multiple aspects at play here: the absolute returns, the time frame over which these were achieved, and the risk taken on the way.`}</p>
    <p>{`By applying leverage, it is possible to scale absolute returns arbitrarily. To compare strategies fairly, we need to evaluate if the profits made are worth the risk. This concept is called risk-adjusted returns.`}</p>
    <p>{`Further, it is helpful to measure returns against a benchmark. However, it is not always straightforward to decide which benchmark might be relevant. We are typically looking for a benchmark with similar diversification and tail risk as our strategy. For strategies investing only in the stock market, that is a cap-weighted market index. For strategies investing in multiple asset classes, we typically benchmark against a 60/40 portfolio. All of our portfolios have historically shown better risk-adjusted returns than their benchmark.`}</p>
    <p>{`When looking at a chart in hindsight, interim drawdowns feel very different from when you experience them live. Many investors initially overestimate their appetite for risk and reconsider when faced with volatility. Therefore, you should focus much more on the risk level than on the absolute returns.`}</p>
    <h2>{`What About Leverage?`}</h2>
    <p>{`For most of our portfolios, we do not use leverage because they seem already risky enough. Investors are free to apply additional leverage as they see fit and if their account type allows doing so. When using leverage, it is crucial to consider borrowing costs and the potential of losing more than your original investment. `}</p>
    <p>{`Tactical asset allocation primarily aims at managing risks, even though this might result in a drag on performance. If a strategy's risk management works particularly well, it is often possible to compensate for the performance hit induced through managing by applying a moderate amount of leverage. One way to achieve superior risk management is through the additional diversification of meta-portfolios. In this context, it might make sense to include aggressively leveraged components while keeping the meta-portfolio's aggregate leverage within moderate limits. `}</p>
    <p>{`We are not opposed to using leverage, but strongly suggest not to take this lightly. It is important that investors understand the implications of leverage and do their thorough due-diligence beforehand.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a863077ce3d5cb574defe2b91106420/e5bc7/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAXZjkZHo/8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBAgMS/9oACAEBAAEFAiZUotrZaHdl/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BhD//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAgEBPwG1v//EABsQAAIDAAMAAAAAAAAAAAAAAAABESIxITKR/9oACAEBAAY/AsfglD5OrNZMuoraj//EABwQAAICAgMAAAAAAAAAAAAAAAABESExUUGB8P/aAAgBAQABPyGEZTbqHmhPYS52M5zGqyF8sh//2gAMAwEAAgADAAAAEKP/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxCNQ//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPxCsU//EAB8QAQACAQMFAAAAAAAAAAAAAAERIQAxQVFhgZGh8P/aAAgBAQABPxB5juwKhaA2YJoxv8/eCEvAbPDFmZKTJZ6xgzrFtq2tL0z/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "reduce risk, improve returns",
            "title": "reduce risk, improve returns",
            "src": "/static/6a863077ce3d5cb574defe2b91106420/e5166/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg",
            "srcSet": ["/static/6a863077ce3d5cb574defe2b91106420/f93b5/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg 300w", "/static/6a863077ce3d5cb574defe2b91106420/b4294/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg 600w", "/static/6a863077ce3d5cb574defe2b91106420/e5166/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg 1200w", "/static/6a863077ce3d5cb574defe2b91106420/d9c39/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg 1800w", "/static/6a863077ce3d5cb574defe2b91106420/df51d/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg 2400w", "/static/6a863077ce3d5cb574defe2b91106420/e5bc7/jeremy-thomas-FO7bKvgETgQ-unsplash.jpg 5184w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`What does it Take?`}</h2>
    <p>{`Most importantly, investing with `}<em parentName="p">{`TuringTrader.com`}</em>{` takes `}<em parentName="p">{`patience`}</em>{`. Returns neither come overnight nor as a guaranteed steady income stream. Further, investing takes much `}<em parentName="p">{`discipline`}</em>{`. It works best if you stick to the rebalancing routine. Also, it helps if you have the `}<em parentName="p">{`courage`}</em>{` to stay the course in rough times and not second-guess the strategies. With historical backtesting, we have validated the portfolios and their behavior in turbulent times. We recommend having some faith in these results and resist the temptation to divest. And finally, it is an absolute requirement for you to know your risk limits. Do not overplay your hand by risking too much: either by choosing a strategy that is too risky for you or by investing more than you can afford.`}</p>
    <p>{`So while you might not get rich quickly, it is important you start investing your savings as early as possible. We hope that `}<em parentName="p">{`TuringTrader.com`}</em>{` will help you find your way, and simplify the process.`}</p>
    <ButtonPrimary text="explore our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      